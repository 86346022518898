import React from "react";
// import ReactDOM from "react-dom";

import { StrictMode } from "react";
import { createRoot } from "react-dom/client";

import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route,
  BrowserRouter,
  redirect,
  useLoaderData,
} from "react-router-dom";

import { Provider } from "react-redux";
// import { Provider, useSelector } from "react-redux";

import store from "./reducers/store";

// import { ThemeProvider } from "@mui/material/styles";
// import { darkTheme, ligthtTheme } from "./utils/theming";

import Contacts, {
  loader as contactsLoader,
  action as contactsAction,
} from "./components/Contacts/Contacts";

import Contact, {
  loader as contactLoader,
} from "./components/Contacts/Contact";

import EditContact, {
  loader as editLoader,
  action as editAction,
} from "./components/Contacts/EditContact";

import { action as destroyAction } from "./components/Contacts/Destroy";

// import * as serviceWorker from "./serviceWorker";
// import "./assets/css/index.css";

// import CommApp from "./components/CommApp";
// import ToDoApp from "./components/ToDoApp";
// import TestBedApp from "./components/TestBedApp";
// import UserControlApp from "./components/UserControlApp";
import App from "./components/App/App";
// import ContactsApp from "./components/ContactsApp";
// import KeeperApp from "./components/KeeperApp";
// import EmojipediaApp from "./components/EmojipediaApp";
// import reportWebVitals from "./reportWebVitals";

import ErrorPage from "./components/Contacts/error-page";
import SpinnerOfDoom from "./components/Utils/LoadingSpinner";

// import Navigation from "./components/Navigation/Navigation";

// import About from "./components/About/About";
// import Home from "./pages/Home";
import AuthService from "./services/auth.service";
// import { ErrorPage as LayoutError } from "./components/Contacts/error-page";
// import LayoutError from "./components/Contacts/error-page";

// var numbers = [3, 56, 2, 48, 5];

// function doubleNumbers(number) {
//   return 2*number;
// }

// function filterFunction(number) {
//   return number % 2 === 0;
// }

// var newNumbers = [];

// function newDoubleNumbers(number) {
//   newNumbers.push(number*2);
// }
// numbers.forEach(newDoubleNumbers);

// console.log(newNumbers);

// console.log(numbers.map(doubleNumbers))
// console.log(numbers.map( number => 2*number));

// console.log(numbers.filter(filterFunction))
// console.log(numbers.filter( number => number % 2 === 0));

// function reducerFunction(accumulator, currentValue) {
//   return accumulator + currentValue;
// }

// console.log(numbers.reduce(reducerFunction))

// console.log(numbers.find(element => element > 50))

// function condition(element) {
//   return element > 50;
// }
// console.log(numbers.findIndex(condition))

// ReactDOM.render(
//   <React.StrictMode>
//     <CommApp />
//   </React.StrictMode>,
//   document.getElementById("comm")
// );

// ReactDOM.render(
//   <React.StrictMode>
//     <ToDoApp />
//   </React.StrictMode>,
//   document.getElementById("todo")
// );

// ReactDOM.render(
//   <React.StrictMode>
//     <TestBedApp />
//   </React.StrictMode>,
//   document.getElementById("testbed")
// );

// ReactDOM.render(
//   <React.StrictMode>
//     <UserControlApp />
//   </React.StrictMode>,
//   document.getElementById("usercontrol")
// );

// ReactDOM.render(
//   <React.StrictMode>
//     <KeeperApp />
//   </React.StrictMode>,
//   document.getElementById("keeper")
// );

// ReactDOM.render(
//   <React.StrictMode>
//     <ContactsApp />
//   </React.StrictMode>,
//   document.getElementById("contacts")
// );

// ReactDOM.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <BrowserRouter>
//         <App />
//       </BrowserRouter>
//     </Provider>
//   </React.StrictMode>,
//   document.getElementById("root")
// );

// AuthProvider.js

export function AuthProvider() {
  //{token: '4r8gjifendinsd'}
  const user = AuthService.getCurrentUser();
  //{"token":"asdasdasd"}
  console.log(JSON.stringify(user));
  if (!user.token) {
    throw redirect("/sign-in");
  }
  return user;
}

// DataLayout.js

export function DataLayout() {
  const user = useLoaderData();
  //undefined
  console.log(user);
}

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

// const theme = "dark" === "dark" ? darkTheme : ligtht;

// const contactRoute = {
//   // const route = {
//   // path: "/contacts/*",
//   // element: <App />,
//   // errorElement: <ErrorPage />,
//   // children: [
//   //   {
//   path: "/contacts",
//   element: <Contacts />,
//   errorElement: <ErrorPage />,
//   action: contactsAction,
//   loader: contactsLoader,
//   children: [
//     {
//       path: "/contacts/:contactId",
//       element: <Contact />,
//       loader: contactLoader,
//     },
//     {
//       path: "/contacts/:contactId/edit",
//       element: <EditContact />,
//       action: editAction,
//       loader: editLoader,
//     },
//     {
//       path: "/contacts/:contactId/destroy",
//       action: destroyAction,
//     },
//   ],
//   //   },
//   // ],
// };

// const route = {
//   path: "/*",
//   element: <App />,
//   errorElement: <ErrorPage />,
//   // children: [
//   //   {
//   //     path: "contacts",
//   //     element: <Contacts />,
//   //     errorElement: <ErrorPage />,
//   //     action: contactsAction,
//   //     loader: contactsLoader,
//   //     children: [
//   //       {
//   //         path: "contacts/:contactId",
//   //         element: <Contact />,
//   //         loader: contactLoader,
//   //       },
//   //       {
//   //         path: "contacts/:contactId/edit",
//   //         element: <EditContact />,
//   //         action: editAction,
//   //         loader: editLoader,
//   //       },
//   //     ],
//   //   },
//   // ],
// };

const route = {
  path: "/*",
  element: <App />,
  errorElement: <ErrorPage />,
  // loader: rootLoader,
  // children: [
  //   {
  //     path: "team",
  //     element: <Team />,
  //     loader: teamLoader,
  //   },
  // ],
};

// const routes = createRoutesFromElements(<App />);

// const router = createBrowserRouter(routes);
// const router = createBrowserRouter([contactRoute, route]);
const router = createBrowserRouter([route]);
// const router = createBrowserRouter([
// createRoutesFromElements(
//   <>
//     <Route path="home" element={<Home />} />
//     <Route path="/" element={<App />} errorElement={<LayoutError />}>
//       <Route
//         path="data"
//         // loader={AuthProvider}
//         element={<DataLayout />}
//       />
//       <Route path="sobre" element={<About />} />
//     </Route>
//   </>
// ),
//   route,
// ]);
// let thetUser;
// export default function Root() {
//   const { user: currentUser } = useSelector((state) => state.auth);
  // thetUser = currentUser;
//   return 
// }
// let currentUser = thetUser;

// Root();


root.render(
    <StrictMode>
      {/* <ThemeProvider theme={theme}> */}
      <Provider store={store}>
        {/* <Navigation currentUser={undefined} /> */}
        <RouterProvider router={router} fallbackElement={<SpinnerOfDoom />} />
        {/* <BrowserRouter>
        <App />
      </BrowserRouter> */}
      </Provider>
      {/* </ThemeProvider> */}
    </StrictMode>
  );
// ReactDOM.render(
//   <React.StrictMode>
//     <EmojipediaApp />
//   </React.StrictMode>,
//   document.getElementById("emojipedia")
// );

// document.getElementById("keeperroot").style.textDecoration = "line-through";

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
